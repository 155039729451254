<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
  >
    <v-card>
      <v-card-title>
        Невозможно прекратить действие маршрута
      </v-card-title>

      <v-divider />

      <v-card-text class="pt-4">
        Невозможно прекратить действие маршрута по причине:
        <ul>
          <li
            v-for="message in blockingMessages"
            :key="message.response.text"
          >
            {{ message.response.text }}
            <ol v-if="message.items && message.items.length">
              <li
                v-for="(item, index) in message.items"
                :key="message.text + index"
              >
                {{ item }}
              </li>
            </ol>
          </li>
        </ul>
      </v-card-text>
      <v-divider />

      <v-card-actions>
        <v-row dense>
          <v-col class="text-right">
            <v-btn
              color="primary"
              @click="dialog = false"
            >
              Закрыть
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "CloseErrors",
    props: {
      messages: {
        type: Array,
        required: true,
      }
    },
    data: () => ({
      dialog: true,
    }),
    computed: {
      blockingMessages() {
        return this.messages.filter(({ response }) => response.is_block);
      }
    },
  }
</script>

